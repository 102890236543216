import { ref } from 'vue'

const getCocktails = () => {
    const cocktails = ref([])
    const error = ref(null)
    const load = async () => {
        try {
        let data = await fetch('https://server.coctelea.app/coctelea_api/cocktails')
        console.log(data)
        if (!data.ok) {
            throw Error('No cocktails available')
        }
        this.cocktails = await data.json()
        } catch (err) {
        error.value = err.message
        console.log(error.value)
        }
    }
    return { cocktails, error, load}
}

export default getCocktails
