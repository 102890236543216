<template>
  <div class="cocktail-card" @click="goToCocktail">
    <img class="icon" :src="cocktail.icon" alt="icon" v-if="cocktail.icon">
    <img class="icon" src="../../../resources/cocktail_model.png" alt="icon" v-else>
    <h3 style="align-self: center;">{{ cocktail.name }}</h3>
<!--    <p v-if="cocktailAllergens" class="cocktail-card-description">{{ cocktailAllergens }}</p>-->
<!--    <p v-if="cocktailIngredients" class="cocktail-card-description">{{ cocktailIngredients }}</p>-->
  </div>
</template>

<script>
import router from "@/router";

export default {
  props: ['cocktail'],
  data() {
      return {
        cocktailAllergens: '',
        cocktailIngredients: '',
      }
    },
  methods: {
    goToCocktail() {
      if (this.cocktail._id) {
        router.push('/cocktail/' + this.cocktail._id)
      } else {
        router.push('/cocktail/' + this.cocktail.id)
      }
    },
    async searchIngredient(id) {
      try {
        let data = await fetch('https://server.coctelea.app/coctelea_api/ingredient/' + id)
        if (!data.ok) throw Error('Ingredients not found')
        return await data.json()
      } catch (err) { console.log(err.message) }
    },
    async searchAllergen(id) {
      try {
        let data = await fetch('https://server.coctelea.app/coctelea_api/allergen/' + id)
        if (!data.ok) throw Error('Allergen not found')
        return await data.json()
        } catch (err) { console.log(err.message) }
      },
  },
}
</script>

<style>
.cocktail-card {
  border-radius: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 100px 1fr 1fr;

  background-color: #F8F4EA;
  box-shadow: 0 4px 5px rgba(27, 27, 27, 0.8);
}

.cocktail-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.cocktail-card > .cocktail-card-description {
  grid-column: 1 / span 2;
  font-size: 18px;
  text-align: start;
  margin: 4px 16px;
}
.icon {
  width: 88%;
  height: 88%;
  margin: 6%;
}
@media (max-width: 690px) {
  .cocktail-card {
    grid-template-columns: 72px 1fr;
    grid-template-rows: 72px 1fr 1fr;
  }
}
</style>