<template>
  <div class="cocktail-list">
      <div v-for="cocktail in cocktails" :key="cocktail.id">
        <CocktailCard :cocktail=cocktail />
    </div>
  </div>
</template>

<script>
import CocktailCard from './CocktailCard.vue'
export default {
    props: ['cocktails'],
    components: { CocktailCard },
}
</script>

<style>
.cocktail-list {
  display: grid;
  gap: 24px;
  grid-template-columns: 272px 272px 272px;
  grid-auto-flow: dense row;
  justify-content: space-evenly;
}

@media (max-width: 1100px) {
  .cocktail-list {
    grid-template-columns: 272px 272px;
  }
}
@media (max-width: 800px) {
  .cocktail-list {
    grid-template-columns: 248px 248px;
  }
}
@media (max-width: 690px) {
  .cocktail-list {
    grid-template-columns: 80%;
  }
}
</style>