<template>
<div id="landing">
  <span></span>
  <div class="log-form">
    <h2>COCTELEA</h2>
    <div id="log-buttons">
      <label :class="{active: logtype === 1}"><input type="radio" v-model="logtype" v-bind:value="1" checked>LOGIN</label>
      <label :class="{active: logtype === 2}"><input type="radio" v-model="logtype" v-bind:value="2">REGISTER</label>
    </div>
    <div class="form" v-if="logtype === 1">
      <form @submit.prevent="log_login">
        <input type="text" placeholder="nombre de usuario" v-model="user.username">
        <p></p>
        <input type="password" placeholder="contraseña" v-model="user.password">
        <p style="color: #F8F4EA;font-size: 18px;">{{ loginError }}</p>
        <button type="submit">ENTRAR</button>
      </form>
    </div>
    <div v-else class="form">
      <form @submit.prevent="log_register">
        <input type="text" placeholder="nombre de usuario" v-model="user.username">
        <p>{{ usrValErr }}</p>
        <input type="password" placeholder="contraseña" v-model="user.password"><br>
        <p style="color: firebrick">{{ pswdValErr }}</p>
        <label>Fecha de nacimiento: <input type="date" v-model="birth"></label><br>
        <label><input type="checkbox" v-model="agreement">Acepto las normas y condiciones de usuario</label><br>
        <button type="submit">REGISTRARSE</button>
      </form>
    </div>
<!--    <router-link to="/app">CONTINUE WITHOUT ACCOUNT</router-link>-->
  </div>
  <span></span>
</div>
</template>

<script>
import bcrypt from 'bcryptjs';
import axios from 'axios';
import router from "@/router";

export default {
  name: "Start",
  data() {
    return {
      logtype : 1,
      birth: null,
      agreement: false,
      user: {
        id: '',
        username:'',
        password:'',
        icon: '',
        fav_cocktails: [],
        author_cocktails: []
      },
      auxu : null,
      loginError: '',
      pswdValErr: '',
      usrValErr: ''
    }
  },
  methods : {
    async getUserByName() {
      try {
        let data = await fetch('https://server.coctelea.app/coctelea_api/user/name/' + this.user.username);
        if (!data.ok) {
          return false;
        }
        this.auxu = await data.json();
        return true
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async encrypt(value) {
      const saltRounds = 10
      return await bcrypt.hash(value, saltRounds)
    },
    validateUser() {
      const hasNoBlankSpaces = /^\S+$/.test(this.user.username); // Regular expression pattern to match non-whitespace characters

      if (this.user.username.length < 4) {
        this.usrValErr = 'Username must be at least 4 characters long and not contain spaces'
        return false
      }
      if (!hasNoBlankSpaces) {
        this.usrValErr = 'Username cannot contain blank spaces'
        return false
      }
      this.usrValErr = ''
      return true
    },
    validatePassword() {
      let pswd = this.user.password

      const minLength = 8
      const hasUppercase = /[A-Z]/.test(pswd)
      const hasLowercase = /[a-z]/.test(pswd)
      const hasNumber = /\d/.test(pswd)

      if (pswd.length < minLength) {
        this.pswdValErr = 'Password should be at least ' + minLength + ' characters long.'
        return false
      }
      if (!hasUppercase) {
        this.pswdValErr = 'Password should contain at least one uppercase letter.'
        return false
      }
      if (!hasLowercase) {
        this.pswdValErr = 'Password should contain at least one lowercase letter.'
        return false
      }
      if (!hasNumber) {
        this.pswdValErr = 'Password should contain at least one digit.';
        return false
      }
      this.pswdValErr = ''
      return true;
    },
    async log_login() {
      if (await this.getUserByName()) {
        bcrypt.compare(this.user.password, this.auxu.password, async (err, isMatch) => {
          if (err) { console.error(err); }
          if (isMatch) {
            this.user = this.auxu
            window.user = this.user
            await router.push({name: 'app'})
          } else {
            this.loginError = 'User or password incorrect'
          }
        })
      } else {
        this.loginError = 'User or password incorrect'
      }
    },
    async log_register() {
      if (!await this.getUserByName()) {
        if (this.validatePassword() && this.validateUser()) {
          this.user.password = await this.encrypt(this.user.password)
          try {
            const response = await axios.post('https://server.coctelea.app/coctelea_api/users', this.user)
            this.user = {id: '', username:'', password:'', icon: '', fav_cocktails: [], author_cocktails: []}
            this.logtype = 1
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
  }
}
</script>

<style>
#landing {
  height: 60vh; width: 100vw;
  display: grid;
  grid-template-columns: 1fr 40% 1fr;
  padding: 10% 0 0;
}
.log-form {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #F8F4EA;
  text-align: center;
  box-shadow: rgba(27, 27, 27, 0.8) 0 8px 16px;
}
.log-form input[type=radio] {
  opacity: 0;
}
#log-buttons {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 0;
  align-content: center;
}
#log-buttons > label {
  position: relative;
  border-radius: 5px 5px 0 0;
  height: 100%; width: 100%;
  padding: 12px 0 8px;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 18px;
}
#log-buttons input {
  position: absolute;
  top: 0; left: 0; z-index: 1;
  height: 100%; width: 100%;
  transform: rotate(0);
}
#log-buttons input:hover {
  cursor: pointer;
}
.log-form > .form {
  border-radius: 16px;
  padding: 32px 20px 20px;
  background-color: #607D61;
  text-align: center;
  color: #f8f4EA;
}
.form input[type=text], .form input[type=password] {
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  padding: 5px;
  height: 20px; width: 90%;
  background: #F8F4EA;
  align-items: center;
  text-align: start;
  font-size: 16px;
}
.form input[type=date] {
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: #F8F4EA;
  color: #607d61;
}
/* Overrides */
.active {
  background-color: #607D61;
  color: #F8F4EA;
}
@media (max-width: 1000px) {
  #landing {
    grid-template-columns: 1fr 64% 1fr;
  }
}
@media (max-width: 800px) {
  #landing {
  grid-template-columns: 1fr 88% 1fr;
}

}
</style>