<template>
  <div id="coctelea-home">
    <nav id="menu" role="navigation">
        <input type="checkbox" ref="check_menu"/>
        <span></span>
        <span></span>
        <span></span>
        <div id="links">
          <router-link to="/start" @click="logOut" title="CERRAR SESION"><span class="material-symbols-outlined">logout</span></router-link>
          <router-link to="/app" @click="closeMenu" title="INICIO"><span class="material-symbols-outlined">home</span></router-link>
          <router-link to="/cocktails" @click="closeMenu" title="BUSCAR"><span class="material-symbols-outlined">search</span></router-link>
          <router-link to="/profile" @click="closeMenu" title="PERFIL"><span class="material-symbols-outlined">person</span></router-link>
        </div>
    </nav>
    <h1 style="color: #8E3A59">COCTELEA</h1>
    <div id="app-content">
      <div>
        <router-view v-if="$route.path !== '/app'"/>
        <Landing v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import Landing from "@/views/Landing";
export default {
  name: "CocteleaHome",
  components: {Landing},
  methods: {
    closeMenu() {
      this.$refs.check_menu.checked = false;
    },
    logOut() {
      window.user = null
    }
  }
}
</script>

<style>
#coctelea-home {
  margin-top: 64px;
}
#coctelea-home > h1 {
  color: #F8F4EA;
}
#app-content {
  width: 80%;
  display: grid;
  /*grid-template-columns: 1fr 72% 1fr;*/
  margin: 0 auto;
  transition: 0.5s ease-in-out;
}
#app-content > div{
  transition: 0.5s ease-in-out;
  text-align: center;
}
#app-content > div > div {
  background-color: rgba(248, 244, 234, 0.96);
  border-radius: 20px;
  box-shadow: rgba(27, 27, 27, 0.8) 0 8px 16px;
  margin: 32px auto;
  padding: 32px 0;
  width: 100%;
  transition: 0.6s ease-in-out;
  text-align: center;
}

/* Menu */
#menu {
  display: block;
  position: fixed;
    top: 0; left: 0; z-index: 96;
}
#menu input {
  position: absolute;
  display: block;
  align-self: center;
  height: 40px; width: 40px; z-index: 100;
  opacity: 0;
  cursor: pointer;
  top: 4px; left: 4px;
  transition: 0.5s ease-in-out;
}
#menu > span {
  display: block;
  position: relative;
  width: 40px; height: 4px;
  border-radius: 5px;
  margin-bottom: 6px;
  z-index: 99;
  top: 12px; left: 8px;
  background-color: #36454F;
  transition: 0.5s ease-in-out;
}
#menu > span:first-child {transform-origin: 0 0;}
#menu > span:nth-last-child(2) {transform-origin: 0 100%;}
#menu input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(10px, 8px);
  align-self: center;
}
#menu input:checked ~ span:nth-last-child(2) {
  opacity: 0;
  transform: scale(0.2, 0.2);
}
#menu input:checked ~ span:nth-last-child(3) {
  transform: rotate(-45deg) translate(-1px, 2px);
}
#menu input:checked ~ #links {
  transform: none;
}

#links {
  position: absolute;
  margin: -72px 0 0;
  padding: 96px 0 48px;
  width: 64px; height: 110vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f4ea;
  transform-origin: 0 0;
  transform: translate(-100%, 0);

  transition: 0.5s ease-in-out;
  text-align: left;
  box-shadow: rgba(27, 27, 27, 0.8) 0 8px 16px;;
}
#links a {
  transition: color 0.3s ease;
  margin: 0 auto 20px;
  text-decoration: none;
  color: #222831;
  font-size: 18px;
}
#links .material-symbols-outlined {
  font-size: 24px;
}
#links a.router-link-exact-active {
  color: #607d61;
}
#links button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
#links a:hover, #links button:hover {
  color: #607d61;
  cursor: pointer;
}

/* Child generic styles */
.pill {
  border: 2px solid #607d61;
  border-radius: 25px;
  background: #F8F4EA;
  padding: 8px;
}
.icon {
  height: 64px;
  width: 64px;
  border-radius: 8px;
}

/* Multi tab menus */

.multi-tab-menu {
  margin: 0 32px;
}

.mtm-options {
  position: relative;
  display: grid;
  align-content: center;
  padding: 20px 0 0;
}
.mtm-options > label {
  position: relative;
  border-radius: 5px 5px 0 0;
  height: 100%; width: 100%;
  padding: 12px 0 8px;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 18px;
}

.mtm-options .active {
  background-color: #AFC9B0;
  color: #F8F4EA;
  font-weight: bold;
}

.mtm-options input[type=radio] {
  position: absolute;
  top: 0; left: 0; z-index: 1;
  height: 100%; width: 100%;
  transform: rotate(0);
  opacity: 0;
}

.mtm-options input[type=radio]:hover {
  cursor: pointer;
}

.mtm-options-display {
  border-radius: 8px 8px 18px 18px;
  padding: 20px;
  background-color: #AFC9B0;
}

/* Media overrides */
@media(max-width: 580px) {
  #app-content {
  grid-template-columns: 100%;
}
  #app-content >  div > div {
    width: 100%;
    border-radius: 20px;
    padding: 0;
  }
  body:has(#menu > input:checked) .content > div {
      transform:translateX(120%);
  }
}

@media(min-width: 581px) {
  #links {
    transform: none;
  }
  #links .material-symbols-outlined {
  font-size: 32px;
}
  #menu input, #menu > span {
    display: none;
  }
}
</style>