<template>
<div>
  <div id="cocktail-form">
    <form @submit.prevent="">
      <h3>NUEVO COCTEL</h3>
      <label>NOMBRE</label><input type="text" v-model="cocktail.name" class="no-button" placeholder="NOMBRE">
      <label>DESCRIPCIÓN</label><input type="text" v-model="cocktail.description" class="no-button" placeholder="DESCRIPCION">
      <label>INGREDIENTES</label><div style="display: flex">
        <input type="text" placeholder="INGREDIENTE" v-model="fieldIngredientName" style="width: 44%" @keyup="searchIngredients">
        <div id="selected-ingredient" style="width: 16%; margin: auto 3%">{{ ingredientSelected._id }} {{ ingredientSelected.measure }}</div>
        <input type="number" v-model="fieldIngredientCant" style="width: 18%" placeholder="0">
    </div><button @click="addIngredient" class="material-symbols-outlined">add</button>
      <div class="item-list">
        <div v-for="ingredient in ingredientsResultSet" :key="ingredient._id" class="ingredient pill" @click="prepareAddIngredient(ingredient)">
                {{ ingredient.name }}
        </div>
      </div>
      <div class="item-list">
        <div  v-for="ingredient in this.cocktail.ingredients" :key="ingredient.id" @click="delIngredient(ingredient.id)">{{ ingredient.id }} {{ ingredient.cant}}</div>
      </div>
      <label>MATERIALES</label><input type="text" v-model="fieldMaterial" placeholder="COCTELERA, COPA MARTINI..."><button @click="addMaterial" class="material-symbols-outlined">add</button>
      <div class="item-list">
        <div v-for="material in cocktail.materials" :key="material" @click="delMaterial(material)">{{ material }} | <span class="material-symbols-outlined">close</span> </div>
<!--        TODO: finish tags, etiquetas-->
      </div>
      <div class="tag-list">
        <div v-for="tag in tags.items" :key="tag._id" @click="toggleTag(tag._id)" :class="[tag._id, {'unselected': isTagSelected(tag._id)}]">{{ tag.name.es }}</div>
      </div>
      <label>PASOS</label><input type="text" v-model="step" placeholder="Meter en la coctelera..., Agitar bien..."><button @click="addStep" class="material-symbols-outlined">add</button>
      <div class="item-list" style="display: inline-block">
        <div v-for="step in stepList" :key="step" @click="delStep(step)">{{ step }} | <span class="material-symbols-outlined">close</span> </div>
      </div>
      <span></span><button type="submit" style="width: 64%;" class="material-symbols-outlined" @click="uploadCocktail">publish</button><span></span>
    </form>
  </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "CocktailForm",
  data() {
    return {
      cocktail: {
        _id: "",
        name: "",
        author: "",
        description: "",
        icon: "",
        ingredients: [],
        materials: [],
        tags: [],
        recipe: ""
      },
      ingredientSelected: {},
      fieldIngredientName: "",
      fieldIngredientCant: 0,
      ingredientsResultSet: [],
      fieldMaterial: "",
      tags: [],
      step: "",
      stepList: [],
      authCocktailList: [],
      ingredientsShow: [],
    }
  },
  methods: {
    async uploadCocktail() {
      // Set cocktail _id and name to uppercase
      let upperCase = this.cocktail.name.toUpperCase();
      this.cocktail._id = upperCase.replaceAll(' ', '-');
      this.cocktail.recipe = this.stepList.join('/&ve&/');
      let data = JSON.parse(JSON.stringify(this.cocktail))
      console.log(data)
      //TODO : Set cocktail.author to window.user.username or window.user.id
      // this.cocktail.author = window.user.username
      // this.cocktail.author = window.user.id
      await axios.post('https://server.coctelea.app/coctelea_api/cocktails', data)
      window.user.author_cocktails.push({"id": this.cocktail._id})
      await axios.put('https://server.coctelea.app/coctelea_api/user/' + window.user._id, window.user)
    },

    // INGREDIENTS
    prepareAddIngredient(ingredient){
      this.ingredientSelected = ingredient
      this.fieldIngredientName= ''
      this.ingredientsResultSet = []
    },
    addIngredient() {
      if (this.ingredientSelected){
        if (this.cocktail.ingredients.findIndex((ingredient) => ingredient.id === this.ingredientSelected._id) === -1) {
        this.cocktail.ingredients.push({id: this.ingredientSelected._id, cant: this.fieldIngredientCant})
        }
      }
      // if (!this.cocktail.ingredients.includes(this.fieldIngredient)) {
      //   this.cocktail.ingredients.push({id: this.temporalIngredienet._id, cant: this.ingredientAmount});
      //   this.ingredientsShow.push({
      //     _id: this.temporalIngredienet._id,
      //     name: this.temporalIngredienet.name,
      //     cant: this.ingredientAmount,
      //     measure: this.temporalIngredienet.measure
      //   });
      // }
      // this.ingredientsResultSet = []
      this.ingredientSelected = {}
      this.fieldIngredientName = ''
      this.fieldIngredientCant = 0
    },
    delIngredient(id) {
      const pos = this.cocktail.ingredients.findIndex((ingredient) => ingredient._id === id)
      this.cocktail.ingredients.splice(pos, 1)
      this.ingredientsShow.splice(pos, 1)
    },
    // addIngredientToTemporal(ingredient) {
    //   this.temporalIngredienet = ingredient
    //   this.fieldIngredient = ingredient.name
    //   this.ingredientsResultSet = []
    //   console.log(ingredient)
    // },
    async searchIngredients() {
      this.ingredientsResultSet = []
      if (!this.fieldIngredientName.length < 1) {
        try {
          let data = await fetch('https://server.coctelea.app/coctelea_api/ingredients/' + this.fieldIngredientName)
          if (!data.ok) throw Error('Ingredients not found')
          this.ingredientsResultSet = await data.json()
        } catch (err) {
          console.log(err.message)
        }
      }
    },
    addMaterial() {
      if (!this.cocktail.materials.includes(this.fieldMaterial)) {
        this.cocktail.materials.push(this.fieldMaterial);
      }
      this.fieldMaterial = '';
    },
    delMaterial(material) {
      const pos = this.cocktail.materials.findIndex((cocktailMaterial) => cocktailMaterial === material)
      this.cocktail.materials.splice(pos, 1)
    },
    async searchTags() {
      //TODO: Change the field write tag to select tags (Less text, more visual)
      try {
        let data = await fetch('https://server.coctelea.app/coctelea_api/tags')
        this.tags = await data.json()
      } catch (e) {
        console.log(e)
      }
    },
    toggleTag(tagId) {
      let position = this.cocktail.tags.findIndex((tag) => tag.id === tagId)
      if (position !== -1) {
        // If exists
        this.cocktail.tags.splice(position, 1)
      } else {
        // If not exists
        this.cocktail.tags.push({id: tagId})
      }
    },
    isTagSelected(tagId) {
      return this.cocktail.tags.findIndex((tag) => tag.id === tagId) === -1
    },
    addStep() {
      this.stepList.push(this.step);
      this.step = '';
    },

    delStep(step) {
      const pos = this.cocktail.steps.findIndex((cocktailStep) => cocktailStep === step)
      this.stepList.splice(pos, 1)
    }
  },
  async beforeMount() {
    await this.searchTags()
  }
}
</script>

<style>
#cocktail-form {
  position: relative;
  display: inline-flex;
  width: 96%;
}
#cocktail-form form {
  margin: 8px;
  border-radius: 32px;
  padding: 12px 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  gap: 8px;
  background-color: #AFC9B0;
}
#cocktail-form h3, #cocktail-form button[type=submit] {
  grid-column: 1 / span 3;
  display: block;
}
#cocktail-form label {
  border-radius: 12px;
  padding: 4px;
  background-color: #F8F4EA;
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
}
#cocktail-form input[type=text], #cocktail-form input[type=number], #selected-ingredient {
  border-radius: 12px;
  border: 1px solid #607d61;
  padding: 4px 4px 4px 8px;
  max-width: 94%;
  text-align: left;
  background-color: #F8F4EA;
}

#cocktail-form button {
  margin: auto;
  width: 88%;
  background-color: #607d61;
  font-weight: bold;
}
#cocktail-form button:hover {
  cursor: pointer;
}

#cocktail-form .no-button {
  grid-column: 2 / span 2;
  width: 100%;
}
#cocktail-form .item-list {
  grid-column: 1 / span 3;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
#cocktail-form .item-list div{
  display: inline-flex;
  border-radius: 25px;
  border: 1px solid #607d61;
  padding: 4px 8px;
  background-color: #F8F4EA;
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
}
#cocktail-form .item-list *:hover {
  cursor: pointer;
}

#cocktail-form .tag-list {
  grid-column: 1 / span 3;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

#cocktail-form .tag-list div{
  display: inline-flex;
  border-radius: 10px;
  padding: 4px 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  flex-grow: 1;
}

#cocktail-form .tag-list div.unselected {
  background: #F8F4EA;
  border: 1px solid #607d61;
}

#cocktail-form .tag-list *:hover {
  cursor: pointer;
}
@media(max-width: 580px) {
  #cocktail-form form { grid-template-columns: 4fr 1fr}
  #cocktail-form label {display: none}
  #cocktail-form h3, #cocktail-form .no-button, #cocktail-form .item-list, #cocktail-form button[type=submit], #cocktail-form .tag-list { grid-column: 1 / span 2;}
}
</style>