<template>
<div id="home-landing">
  <div>
    <h3>Welcome {{ user.username }}</h3>
  </div>
</div>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {
      user: window.user
    }
  }
}
</script>

<style>
#home-landing {
  height: fit-content;
}
#home-landing h3 {
  text-align: center;
  width: 90%;
  margin: auto;
}
</style>