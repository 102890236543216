import { createRouter, createWebHistory } from 'vue-router'
import Start from "../views/Start.vue";
import WebHome from '../views/WebHome.vue'
import CocteleaHome from '../views/CocteleaHome.vue'
import CocktailDetails from '../views/CocktailDetails.vue'
import NotFound from '../views/NotFound.vue'
import Profile from "../views/Profile";
import CocktailSearch from "@/views/CocktailSearch";

const routes = [
    {
    path: '/start',
    name: 'start',
    component: Start
    },
    {
      path: '/',
      name: 'home',
      component: WebHome
    },{
        path: '/app',
        name: 'app',
        component: CocteleaHome,
        meta: {
            requireAuth: true
        },
        children: [
            {
              path: '/cocktails',
              name: 'cocktails',
              component: CocktailSearch
            },{
              path: '/cocktail/:id',
              name: 'cocktail-details',
              component: CocktailDetails,
              props: true
            },{
                path: '/profile',
                name: 'profile',
                component: Profile
            },
            // Catch all 404
            {
              path: '/:catchAll(.*)',
              name: 'not-found',
              component: NotFound
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth && !window.user) {
        return next('/')
    } else {
        return next()
    }
})

export default router
