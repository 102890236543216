<template>
  <div>
    <div><h3>{{ user.username }}</h3></div>
    <div id="profile" class="multi-tab-menu">
      <div id="profile-options" class="mtm-options">
        <label :class="{active: opts === 1}"><input type="radio" v-model="opts" @click="prepareFavCocktails" v-bind:value="1" checked><span>GUARDADOS</span><span class="material-symbols-outlined">bookmarks</span></label>
        <label :class="{active: opts === 2}"><input type="radio" v-model="opts" @click="showAuthCocktails" v-bind:value="2">MIS CÓCTELES</label>
        <label :class="{active: opts === 3}"><input type="radio" v-model="opts"  v-bind:value="3"><span class="material-symbols-outlined" style="font-size: 32px">add</span></label>
      </div>
      <div class="mtm-options-display">
        <div v-if="opts === 1 || opts === 2 ">
            <CocktailCardList :cocktails="cocktails"/>
        </div>
        <div v-else-if="opts === 3">
          <CocktailForm/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import CocktailCardList from '../components/CocktailCardList.vue'
import CocktailForm from "@/components/CocktailForm";
export default {
    components: {CocktailForm, CocktailCardList },
    data() {
      return {
        opts: 1,
        user: window.user,
        cocktails: [],
    };
  },
  beforeMount() {
    this.prepareFavCocktails()
  },
  methods: {

    async prepareFavCocktails() {
      this.cocktails = []
      try {
        for (let cocktail_fav of this.user.fav_cocktails) {
          const response = await fetch("https://server.coctelea.app/coctelea_api/cocktail/" + cocktail_fav.id)
          this.cocktails.push(await response.json())
        }
      } catch (error) {
        console.log(error)
      }
    },

    async showAuthCocktails() {
      this.cocktails = []
      try {
        for (let cocktail_auth of this.user.author_cocktails) {
          const response = await fetch("https://server.coctelea.app/coctelea_api/cocktail/" + cocktail_auth.id)
          this.cocktails.push(await response.json())
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
#profile-options {
  grid-template-columns: 1fr 1fr 64px;
  grid-template-rows: auto 0;
}
</style>