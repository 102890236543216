<template>
    <div class="content">
      <div id="client-tools">
        <span :class="{'saved': isSaved }" class="material-symbols-outlined" @click="toggleSave" style="color: #607d61; font-size: 24px">bookmark</span>
      </div>
        <div id="cocktail-details" v-if="cocktailDetails">
<!--            <h2>{{ id }}</h2>-->
          <img v-if="cocktailDetails.icon" :src="cocktailDetails.icon" alt="la imagen del coctel" id="cocktail-image">
          <img v-else src="../../../resources/cocktail_model.png" id="cocktail-image">
          <div style="display: inline-flex; margin: 16px; flex-flow: row-reverse;">
            <h2>{{ cocktailDetails.name }}</h2>
            <div v-for="tag in cocktailDetails.tags" :key="tag.id" class="tag" :class="tag.id">
              {{ tag.id }}
            </div>
          </div>
          <h3>{{ cocktailDetails.description }}</h3>
          <div id="cocktail-ingredients">
            <div v-for="ingredient in cocktailIngredientsList" :key="ingredient.id">
              <img :src="ingredient.symbol" alt="" class="ingredient-icon">
              <h5>{{ ingredient.id }}</h5>
              <h5>{{ ingredient.cant }} {{ ingredient.measure }}</h5>
            </div>
          </div>
          <div id="cocktail-steps">
            <h3 style="text-align: start">PASOS</h3>
            <ol>
              <li v-for="step in cocktailDetails.recipe.split('/&ve&/')" :key="step">{{ step }}</li>
            </ol>
          </div>
        </div>
        <div v-else>
            <h1>PREPARING YOUR COCKTAIL</h1>
        </div>

    </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
      return {
        id: '',
        cocktailDetails: {},
        cocktailIngredientsList: [],
        steps: [],
        isSaved: false
      }
  },
  created() {
    this.getCocktailById()
    this.isSaved = window.user.fav_cocktails.findIndex((cocktail) => cocktail.id === this.id) !== -1
  },
  async beforeUnmount() {
    try {
      await axios.put('https://server.coctelea.app/coctelea_api/user/' + window.user._id, window.user)
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    async getCocktailById(){
      this.id = this.$route.params.id
      let data = await fetch('https://server.coctelea.app/coctelea_api/cocktail/' + this.id)
      if (!data.ok) throw Error('could not fetch cocktail: ' + this.id)
      this.cocktailDetails = await data.json()
      console.log(this.cocktailDetails)
      await this.getCocktailIngredients()
    },
    async getCocktailIngredients() {
      try {
        for (let ingredient of this.cocktailDetails.ingredients) {
          let data = await fetch('https://server.coctelea.app/coctelea_api/ingredient/' + ingredient.id)
          if (!data.ok) throw Error('Ingredient not found: ' + ingredient.id)
          this.cocktailIngredientsList.push(Object.assign({}, ingredient, await data.json()))
        }
      } catch (err) {
        console.log(err.message)
      }
    },
    async toggleSave(){
      let position = window.user.fav_cocktails.findIndex((cocktail) => cocktail.id === this.id)
      if (position === -1 ) {
        window.user.fav_cocktails.push({"id": this.id})
      } else {
        window.user.fav_cocktails.splice(position, 1)
      }
      this.isSaved = !this.isSaved;
    }
  }
}
</script>


<style>
#client-tools {
  position: relative;
  left: 24px;
  border-radius: 24px;
  width: 24px;
  padding: 4px;
  display: flex;
  flex-flow: row;
  background: #AFC9B0;
}
#cocktail-details {
  display: grid;
  grid-template-columns: 1fr 256px;
  gap: 8px;
  width: 88%;
  margin: 24px auto;
}
#cocktail-details h2, #cocktail-details h3 {
  align-self: center;
  text-align: end;
}
#cocktail-image {
  grid-column: 2;
  grid-row: 1 / span 2;
  width: 196px;
  margin: auto;
}
#cocktail-steps {
  text-align: start;
}
#cocktail-ingredients {
  grid-column: 1 / span 2;
}
#cocktail-ingredients > div {
  margin: auto;
  width: fit-content;
  display: grid;
  grid-template-columns: 64px 192px 96px;
}
#cocktail-ingredients > div > h5 {
  align-self: center;
}
.ingredient-icon {
  height: 64px;
  padding: 0;
}
.tag {
  margin: auto 16px;
  border-radius: 16px;
  padding: 8px 0;
  width: 96px;
  text-align: center;
}
.CLASSY {
  background: linear-gradient(to right, #FFD2D2, #FFB4B4);
}

.BITTER {
  background: linear-gradient(to right, #FFDDB4, #FFC78C);
}

.ENERGETIC {
  background: linear-gradient(to right, #B4FFC1, #8CFFA6);
}

.DRY {
  background: linear-gradient(to right, #B4FFE4, #8CFFCD);
}

.HOT {
  background: linear-gradient(to right, #FFB4E6, #FF8CCD);
}

.REFRESHING {
  background: linear-gradient(to right, #B4EFFF, #8CCDFF);
}

.SOUR {
  background: linear-gradient(to right, #FFF3B4, #FFD88C);
}

.SPICY {
  background: linear-gradient(to right, #FFB4B4, #FF8C8C);
}

.SWEET {
  background: linear-gradient(to right, #FFE1B4, #FFC18C);
}

.ALCOHOL-FREE {
  background: linear-gradient(to right, #B4B4FF, #8C8CFF);
}

.CREAMY {
  background: linear-gradient(to right, #FFEDB4, #FFD68C);
}

.saved {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
</style>