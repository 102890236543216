<template>
  <div class="landing-web">
    <h1 style="font-size: 72px; color: rgba(248,244,234,0.88)">COCTELEA</h1>
    <router-link to="/start" class="router-button">TRY IT <span class="material-symbols-outlined">polyline</span></router-link>
  </div>
  <div class="web-content">
    <div class="web-section">
      <div class="subsection">
        <h3>COCTELEA</h3>
        <p>Most of the people think that cocktails are overly complicated or require many expensive
        ingredients, but we want to demonstrate that's not true.<br>So we created <strong>COCTELEA</strong>... Our passion for mixology and the desire to provide cocktail lovers with an intuitive and comprehensive tool prompted us to create this app. We want to show that enjoying delicious cocktails does not have to be complicated or expensive.</p>
        <img src="../../../resources/icon.png" alt="" class="subsection-resource">
      </div>
      <div class="subsection">
        <h3><STRONG>Discover</STRONG></h3>
        <p>With <STRONG>COCTELEA</STRONG>, you can explore a world of classic cocktail recipes and discover new creations.
          Our extensive database will allow you to find popular and lesser known cocktails, so you always have something exciting to try.</p>
        <span class="subsection-resource material-symbols-outlined">search</span>
      </div>
      <div class="subsection">
        <h3><strong>Create</strong></h3>
        <p>
          There are no limits to your creativity in <STRONG>COCTELEA.</STRONG> You can use our app to create your own custom cocktail recipes. Experiment with different ingredients, measurements, and preparation techniques for unique and surprising results.
          Unleash your talent as a bartender and surprise your friends and family with your creations!
        </p>
        <span class="subsection-resource material-symbols-outlined">add_circle</span>
      </div>
      <div class="subsection">
        <h3><STRONG>Share</STRONG></h3>
        <p> <STRONG>COCTELEA</STRONG> not only gives you the ability to discover and create cocktails, but also allows you to share your favorite recipes with the community. You will be able to show your skills and knowledge in mixology by sharing your creations with other <STRONG>COCTELEA</STRONG> users.</p>
        <span class="subsection-resource material-symbols-outlined">ios_share</span>
      </div>
      <div class="subsection">
        <h3>Why "Coctelea"</h3>
        <p>Our app was born out of a passion for mixology and the desire to provide cocktail lovers with an intuitive and complete tool.
          Designed to make it easy to find existing cocktails, as well as encourage creativity in creating new recipes, our app is the perfect companion for those who
          want to explore, enjoy and share the art of mixology from the comfort of their home or anywhere. <STRONG>Download COCTELEA</STRONG> now and immerse yourself in
          the exciting world of cocktails. Whether you're at home or on the go, our app will be your perfect companion to explore, enjoy, and share the art of mixology.
          Take your cocktail experiences to the next level with <STRONG>COCTELEA!</STRONG> </p>
        <img src="../../../resources/icon.png" alt="" class="subsection-resource">
      </div>
    </div>
    <div style="margin: 72px 0 0">
      <router-link to="/start" class="router-button">TRY IT <span class="material-symbols-outlined">polyline</span></router-link>
    </div>
      <div id="subsection-features" class="web-section">
        <h3 class="features-tittle">Features</h3>
        <div class="feature">
          <h3>Save your favourites</h3>
          <p>Save your favourite cocktails, access them faster from your profile</p>
          <img class="feature-resource" src="../../../resources/images/feature_save_cocktails.png" alt="">
        </div>
        <div class="feature">
          <h3>Create your own</h3>
          <p>Create your own cocktails, share them with your friends and the rest of the community.</p>
          <img class="feature-resource" src="../../../resources/images/feature_create_cocktail.png" alt="">
        </div>
        <div class="feature">
          <h3>Explore cocktails</h3>
          <p>Comfortable and easy search system for cocktails by ingredients or name</p>
          <img class="feature-resource" src="../../../resources/images/feature_explore_cocktails.png" alt="">
        </div>
      </div>
    </div>
    <div>
      <h3>Try Now</h3>
      <div class="button-container">
        <a class="pseudo-button" href="https://play.google.com/store/games?gl=ES&utm_source=emea_Med&utm_medium=hasem&utm_content=Dec2020&utm_campaign=Evergreen&pcampaignid=MKT-EDR-emea-es-1001280-Med-hasem-py-Evergreen-Dec2020-Text_Search_BKWS-test_ctrl_ca%7CONSEM_kwid_43700071469866771&gclid=EAIaIQobChMIoOzGo8CO_wIV1AUGAB00CQYCEAAYASAAEgIPyvD_BwE&gclsrc=aw.ds">
          <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style="width: 250px; height: 100px;margin-bottom: 20px;">
        </a>
        <router-link to="/" class="router-button">TRY IT <span class="material-symbols-outlined">polyline</span></router-link>
      </div>
    </div>
</template>

<script>
export default {
  name: 'WebHome'
}
</script>

<style>
.landing-web {
  height: 100vh;
  background: url(/../resources/wood_bg.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.router-button {
  margin: 0 auto;
  border-radius: 16px;
  padding: 8px 48px;
  width: fit-content;
  display: flex;
  background: rgba(248, 244, 234, 0.8);
  color: #607d61;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  text-align: center;
}
.router-button:hover, .router-button:hover > * {
  cursor: pointer;
}
.web-section {
  margin-top: -20px;
  padding: 20px 0;
  background: #f8f4ea url('data:image/svg+xml,<svg id="visual" viewBox="0 0 1920 1080" width="1920" height="1080" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><path d="M1074 1080L1134.8 1054.3C1195.7 1028.7 1317.3 977.3 1301.3 925.8C1285.3 874.3 1131.7 822.7 1128.5 771.2C1125.3 719.7 1272.7 668.3 1343.2 617C1413.7 565.7 1407.3 514.3 1381.7 463C1356 411.7 1311 360.3 1243.7 308.8C1176.3 257.3 1086.7 205.7 1041.8 154.2C997 102.7 997 51.3 997 25.7L997 0L1920 0L1920 25.7C1920 51.3 1920 102.7 1920 154.2C1920 205.7 1920 257.3 1920 308.8C1920 360.3 1920 411.7 1920 463C1920 514.3 1920 565.7 1920 617C1920 668.3 1920 719.7 1920 771.2C1920 822.7 1920 874.3 1920 925.8C1920 977.3 1920 1028.7 1920 1054.3L1920 1080Z" fill="%23f8f4ea"></path><path d="M786 1080L795.7 1054.3C805.3 1028.7 824.7 977.3 786.3 925.8C748 874.3 652 822.7 645.5 771.2C639 719.7 722 668.3 786 617C850 565.7 895 514.3 891.8 463C888.7 411.7 837.3 360.3 750.8 308.8C664.3 257.3 542.7 205.7 507.5 154.2C472.3 102.7 523.7 51.3 549.3 25.7L575 0L998 0L998 25.7C998 51.3 998 102.7 1042.8 154.2C1087.7 205.7 1177.3 257.3 1244.7 308.8C1312 360.3 1357 411.7 1382.7 463C1408.3 514.3 1414.7 565.7 1344.2 617C1273.7 668.3 1126.3 719.7 1129.5 771.2C1132.7 822.7 1286.3 874.3 1302.3 925.8C1318.3 977.3 1196.7 1028.7 1135.8 1054.3L1075 1080Z" fill="%23afc9b0"></path><path d="M0 1080L0 1054.3C0 1028.7 0 977.3 0 925.8C0 874.3 0 822.7 0 771.2C0 719.7 0 668.3 0 617C0 565.7 0 514.3 0 463C0 411.7 0 360.3 0 308.8C0 257.3 0 205.7 0 154.2C0 102.7 0 51.3 0 25.7L0 0L576 0L550.3 25.7C524.7 51.3 473.3 102.7 508.5 154.2C543.7 205.7 665.3 257.3 751.8 308.8C838.3 360.3 889.7 411.7 892.8 463C896 514.3 851 565.7 787 617C723 668.3 640 719.7 646.5 771.2C653 822.7 749 874.3 787.3 925.8C825.7 977.3 806.3 1028.7 796.7 1054.3L787 1080Z" fill="%23f8f4ea"></path></svg>');
}
.web-section > div {
  margin: 20px auto;
  width: 80%;
}
.subsection {
  margin: 1vh 0;
  border-radius: 48px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  transition: 0.3s ease-in-out;
}
.subsection:hover {
  transform: scale(1.02);
}
.subsection h3 {
  grid-column: 1;
  text-align: start;
}
.subsection-resource {
  grid-row: 1 / span 2;
  grid-column: 2;
  margin: auto;
  max-width: 88%;
  font-size: 96px
}
.subsection p {
  margin: 0;
  grid-row: 2;
  grid-column: 1;
  color: #8E3A59;
  text-align: justify;
}

#subsection-features {
  margin: 72px auto 36px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.features-tittle {
  grid-column: 1 / span 3;
}
.feature p {
  color: #8E3A59;
}
.feature-resource {
  max-width: 96%;
  margin: auto;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.button-container > * {
  margin: auto;
}

@media (max-width: 1200px) {
  #subsection-features {
  grid-template-columns: 1fr 1fr
  }
  .features-tittle {
  grid-column: 1 / span 2;
  }
}

@media (max-width: 800px) {
  #subsection-features {
  grid-template-columns: 1fr
  }
  .features-tittle {
  grid-column: 1;
  }
}
</style>
