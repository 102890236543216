<template>
  <div>
    <div id="search" class="multi-tab-menu">
      <div id="toggle-search" style="padding: 20px 0 0" class="mtm-options">
        <label :class="{active: searchType === 1}"><input type="radio" v-model="searchType" v-bind:value="1" checked @click="clearCocktailResulSet">INGREDIENTES</label>
        <label :class="{active: searchType === 2}"><input type="radio" v-model="searchType" v-bind:value="2" @click="clearCocktailResulSet">CÓCTEL</label>
      </div>
      <div id="search-form" class="mtm-options-display">
        <div v-if="searchType === 1">
        <div class="searchbar pill">
          <input type="text" placeholder="Search ingredient by name and select" v-model="ingredientTextbox" @keyup="searchIngredient">
          <span class="material-symbols-outlined">search</span>
        </div>
        <br>
        <div v-for="ingredient in ingredientInputList" :key="ingredient.id" class="ingredient pill" @click="deleteIngredient(ingredient.name)">
                {{ ingredient.name }}
            </div>
          <hr>
          <div v-for="ingredient in ingredientResultSet" :key="ingredient.id" class="ingredient pill" @click="addIngredient(ingredient)">
                {{ ingredient.name }}
            </div>
        </div>
        <div v-else>
        <div class="searchbar pill">
          <input type="text" placeholder="Search by cocktail name" v-model="cocktailTextbox" @keyup="searchCocktailByName">
          <span class="material-symbols-outlined">search</span>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div v-if="cocktailsResultSet.length !== 0" id="cocktails-result" style="  background-color: transparent; box-shadow: none">
      <CocktailCardList :cocktails=cocktailsResultSet />
  </div>
</template>

<script>
import CocktailCardList from '../components/CocktailCardList.vue'
import getCocktails from '../composables/getCocktails'
export default {
    components: { CocktailCardList },
    data() {
        return {
          searchType: 1,

          ingredientTextbox: '', // The input textbox for ingredients
          ingredientResultSet: [], // The ingredients matching with ingredientTextbox
          ingredientInputList: [], // The cocktails to search with

          cocktailTextbox: '',

          cocktailsResultSet: [], // The result of cocktails from the db
        }
    },
    methods: {
        async searchCocktailByName() {
            this.cocktailsResultSet = []
            if (!this.cocktailTextbox.length < 1) {
                try {
                    let data = await fetch('https://server.coctelea.app/coctelea_api/cocktails/' + this.cocktailTextbox)
                    if (!data.ok) throw Error('Cocktails not found')
                    this.cocktailsResultSet = await data.json()
                } catch (err) { console.log(err.message) }
            }
        },
        async clearCocktailResulSet(){
          this.cocktailsResultSet = []
        },
        async searchCocktailByIngredients() {
            this.cocktailsResultSet = []
            try {
                let data = await fetch('https://server.coctelea.app/coctelea_api/cocktails/')
                if (!data.ok) throw Error('Cocktails not found')
                let result = await data.json()
                let resItems = result.items
                this.cocktailsResultSet = resItems.filter(this.cocktailsFilter)
                console.log(this.cocktailsResultSet)
            } catch (err) { console.log(err.message) }
        },
        async searchIngredient() {
          if (!this.ingredientTextbox.length < 1) {
            try {
              let data = await fetch('https://server.coctelea.app/coctelea_api/ingredients/' + this.ingredientTextbox)
              if (!data.ok) throw Error('Ingredients not found')
              this.ingredientResultSet = await data.json()
              console.log(this.ingredientResultSet)
            } catch (err) { console.log(err.message) }
          } else {
            this.ingredientResultSet = []
          }
        },
        cocktailsFilter(cocktail){

          let temp = this.ingredientInputList
          for ( let x of temp) {
            x = JSON.parse(JSON.stringify(x))
            let ingcocktail = cocktail.ingredients
            for (let cocktailIng of ingcocktail ) {
              if(cocktailIng.id === x._id){
                return true;
              }
            }
          }
          return false;
        },
        addIngredient(ingredient) {
          if (!this.ingredientInputList.includes(ingredient.name)) {
            this.ingredientInputList.push( ingredient)
            this.searchCocktailByIngredients()
          }
          this.ingredientTextbox = ''
          this.searchIngredient()
        },
        deleteIngredient(name) {
            const pos = this.ingredientInputList.findIndex((ingredient) => ingredient.name === name)
            this.ingredientInputList.splice(pos, 1)
            this.searchCocktailByIngredients()
        }
    },
    setup() {
        const {cocktails, error, load} = getCocktails()
        load()
        // cocktails = cocktails.slice(0,3)
        return {cocktails, error}
    }
}
</script>

<style>
#toggle-search {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 0;
}

.searchbar {
  padding: 4px;
  width: 80%;
  display: inline-flex;
  align-items: center;
  background-color: #F8F4EA;
}

.ingredient {
    display: inline-block;
    margin: 20px 10px 0 0;
    border-radius: 18px;
    padding: 8px 16px;
    align-items: center;
    text-align: center;
    cursor: pointer;
    letter-spacing: 1px;
}
/* Overrides */
#cocktails-result {
  align-content: center;
  align-items: center;
}
</style>